import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import queryString from "query-string"
import SEO from "../components/seo"
import ProjectBlock from "../components/projectBlock"
import ProjectBlockConcise from "components/projectBlockConcise"

const IndexPage = ({ location, data }) => {
  const parsedQuery = queryString.parse(location.search)
  // filter projects based on parsed query
  const filteredProjects = data.projects.edges.filter((e) => {
    if ("category" in parsedQuery)
      if (parsedQuery.category === "other")
        return (
          e.node.frontmatter.category !== "design" &&
          e.node.frontmatter.category !== "art"
        )
      else return e.node.frontmatter.category === parsedQuery.category
    else return true
  })

  const featuredProjects = filteredProjects.filter(
    (e) => e.node.frontmatter.featured
  )

  const unfeaturedProjects = filteredProjects.filter(
    (e) => !e.node.frontmatter.featured
  )

  const projects = featuredProjects.concat(unfeaturedProjects)

  const [showMore, setShowMore] = React.useState(true)

  const CurrentProjectBlock = showMore ? ProjectBlock : ProjectBlockConcise

  return (
    // passing about mdx to header
    <Layout location={location} pageType={"home"} setShowMore={setShowMore}>
      <SEO></SEO>
      <div
        id="main"
        className={`px-2 flex flex-col ${
          !showMore && "md:grid md:grid-cols-2 md:gap-x-6"
        }`}
      >
        {projects.map((e, i) => {
          const slug = e.node.slug
          const frontmatter = e.node.frontmatter
          return (
            <CurrentProjectBlock
              slug={slug + "#main-content"}
              frontmatter={frontmatter}
              i={i}
              key={i}
            ></CurrentProjectBlock>
          )
        })}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    projects: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/projects/" }
        frontmatter: { visible: { eq: true } }
      }
      sort: {
        fields: [frontmatter___year, frontmatter___position]
        order: [DESC, DESC]
      }
    ) {
      edges {
        node {
          body
          slug
          frontmatter {
            title
            category
            displayedCategory
            date
            year
            displayed_time
            position
            featured
            description
            notes
            tags
            role
            collaborators
            contributors
            publication
            social
            external
            video
            github
            overrideLink
            embeddedImages {
              childImageSharp {
                gatsbyImageData(width: 1600)
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
